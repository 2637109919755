<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <b-table
            sticky-header="70vh"
            hover
            responsive
            striped
            bordered
            :fields="fields"
            :items="dados"
            @row-clicked="rowClick"
          >
            <template #cell(data_inicio_campanha)="data">
              {{ data.item.data_inicio_campanha ? formatTimezone(data.item.data_inicio_campanha) : '' }}
            </template>

            <template #cell(data_final_campanha)="data">
              {{ data.item.data_final_campanha ? formatTimezone(data.item.data_final_campanha) : '' }}
            </template>

            <template #cell(faturamento_campanha)="data">
              {{ data.item.faturamento_campanha ? valorBr(data.item.faturamento_campanha, true) : 'R$ 0,00' }}
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BTable,
} from 'bootstrap-vue'

import api from '@/../axios-auth'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
  },
  data() {
    return {
      dados: [],
      fields: [
        { key: 'data_inicio_campanha', label: 'Data Início' },
        { key: 'data_final_campanha', label: 'Data Final' },
        { key: 'operacoes', label: 'Qnt. Operações' },
        { key: 'quantidade_acordos', label: 'Qnt. Acordos' },
        { key: 'faturamento_campanha', label: 'Faturamento' },
        { key: 'total_clientes', label: 'Qnt. Clientes' },
      ],
    }
  },
  created() {
    this.getItems()
  },
  methods: {
    rowClick(item) {
      this.$router.push({ name: 'campanhas.view', params: { id: item.id } })
    },
    getItems() {
      api.get('api/v1/dashboard/credor/campanhas', {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          Accept: 'application/json',
        },
      }).then(res => {
        this.dados = res.data.dados
      })
    },
  },
}
</script>
